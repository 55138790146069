import React, { useCallback, useState } from "react";
import { DefaultTFuncReturn, t } from "i18next";
import cn from "../../../utils/cn";
import { Document as ReactPDF, Page, pdfjs } from "react-pdf";
import { File } from "react-pdf/dist/esm/types/pdfjs";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PDFViewer.scss";
import { IValidationCodeProps } from "../ValidationCode/ValidationCode";
import moment from "moment";
import DocumentSign from "../DocumentSign/DocumentSign";
import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query";
import Loader from "../../atoms/Loader/Loader";
import { handleFileDownload } from "../../../utils/blob";
import ViewerNavigation from "../ViewerNavigation/ViewerNavigation";

interface IPDFViewerProps {
	className?: string;
	file?: File;
	isOpen?: boolean;
	onBack?: () => void;
	download?: boolean;
	onLoadSuccess?: (numPages: number) => void;
	onDownload?: () => void;
	onDownloaded?: () => void;
	downloadTitle?: string | DefaultTFuncReturn;
	sign?: IValidationCodeProps | null;
	isSigned?: boolean;
	query?:
		| {
				key: QueryKey;
				fn: QueryFunction;
				options?: any;
		  }
		| undefined;
}

const PDFViewer: React.FC<IPDFViewerProps> = ({
	file = null,
	onBack = () => {},
	download = true,
	onLoadSuccess = () => {},
	onDownload = () => {},
	onDownloaded = () => {},
	sign = null,
	isSigned = false,
	className,
	query,
	downloadTitle = t("docs.fileName") + moment().format("DD_MM_YYYY_HH_mm_ss"),
}) => {
	// needed for it to work
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

	const [numPages, setNumPages] = useState<number | null>(null);
	const [Zoom, setZoom] = useState(1);
	const [ScrollEvent, setScrollEvent] = useState(null);
	const contRef = React.useRef(null);
	const extension = (file || "")?.split(".")?.pop()?.split("?")?.[0] ?? "pdf";

	function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
		if (contRef.current && sign && !isSigned) {
			setTimeout(() => {
				if (!contRef.current) return;
				contRef.current.scrollTo({
					top: contRef.current.scrollHeight,
					behavior: "smooth",
				});
			}, 10); // need to do this because the autoscroll is not working if we don't wait
		}
		setNumPages(numPages);
		onLoadSuccess(numPages);
	}

	// handle zoom with scroll + alt key
	const handleScroll = useCallback((e: any) => {
		setScrollEvent(e);
	}, []);

	const handleSign = (code: string) => {
		if (!sign?.onSubmit)
			return new Promise<void>((resolve, reject) =>
				reject(
					"Sign is not enabled or there is no onSign function provided"
				)
			);
		return sign.onSubmit(code);
	};

	const handleDownload = () => {
		onDownload && onDownload();
		const fileToUse = file ? file : data;

		const prom = handleFileDownload(
			fileToUse,
			downloadTitle,
			extension,
			onDownloaded
		);

		return prom;
	};

	const handleBack = () => {
		onBack();
	};

	React.useEffect(() => {
		// replace horizontal scroll to the middle
		const container: any = contRef.current;
		if (!container) return;
		container.scrollLeft =
			(container.scrollWidth - container.clientWidth) / 2;
	}, [Zoom]);

	React.useEffect(() => {
		const container: any = contRef.current;
		if (!container) return;
		container.addEventListener("wheel", handleScroll);
		return () => {
			container.removeEventListener("wheel", handleScroll);
		};
	}, [handleScroll]);

	const { isInitialLoading, isLoading, data, error } = useQuery(
		query?.key ?? ["PDF Fetch", Date.now().toString()],
		query?.fn ?? (() => {}),
		{
			enabled: !file && !!query,
			refetchOnWindowFocus: false,
			retry: false,
			...(() => (file ? { initialData: file } : {}))(),
			...query?.options,
		}
	);

	return (
		<div
			className={cn(["PDFViewer", className])}
			style={{
				"--viewer-scale-factor": Zoom,
			}}
		>
			<ViewerNavigation
				isLoading={isLoading}
				Zoom={Zoom}
				onZoom={setZoom}
				download={download}
				onDownload={handleDownload}
				onBack={handleBack}
				disabled={!!error || (!!sign && !isSigned)}
				className="PDFViewer_nav"
				scrollEvent={ScrollEvent}
			/>
			{isLoading && isInitialLoading ? (
				<div className="PDFViewer__loader">
					<Loader />
				</div>
			) : (
				<>
					<div
						ref={contRef}
						className="flex flex-col w-full h-full overflow-auto PDFViewer__container bg-neutral-100"
					>
						<ReactPDF
							file={data ?? file}
							loading={<Loader />}
							noData={
								<div>
									{t(
										error ? "global.error" : "global.noData"
									)}
								</div>
							}
							error={<div>{t("global.error")}</div>}
							onLoadSuccess={onDocumentLoadSuccess}
							className={sign ? "!pb-[200px]" : ""}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									scale={Zoom}
									width={window.innerWidth * 0.9}
								/>
							))}
						</ReactPDF>
					</div>
					{!error && sign && !isSigned && (
						<DocumentSign
							sign={sign}
							handleDownload={handleDownload}
							handleSign={handleSign}
							onCancel={handleBack}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default PDFViewer;
