import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { ENV, GTM_ID, SENTRY_URL } from "./constants/constants";
import moment from "moment";
import MomentLocaleFR from "./moment/moment-fr";
import MobileAppBanner from "./components/molecules/MobileAppBanner/MobileAppBanner";
import TagManager from "react-gtm-module";
import { SettingsProvider } from "./contexts/SettingsContext/SettingsContext";
import { pdfjs } from "react-pdf";

const fetchResponse = await fetch(
	`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
);
const code = await fetchResponse.text();
const codeBlob = new Blob([code], { type: "text/javascript" });
const workerUrl = URL.createObjectURL(codeBlob);
pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

if (GTM_ID) {
	const tagManagerArgs = {
		gtmId: GTM_ID,
	};

	TagManager.initialize(tagManagerArgs);
} else {
	console.warn("GTM_ID not found");
}

moment.locale("fr", MomentLocaleFR);

const queryClient = new QueryClient();

if (SENTRY_URL && ENV !== "development")
	Sentry.init({
		dsn: SENTRY_URL,
		environment: ENV,
	});

/*
	the next lines are used to prevent the types errors comming from @zolteam/react-ras-library because of the use of Proptypes.defaultProps in the library
	exemple: "Warning: Tooltip: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead."
*/
const error = console.error;
console.error = (...args: any) => {
	if (/defaultProps/.test(args[0])) return;
	error(...args);
};

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<SettingsProvider>
				<QueryClientProvider client={queryClient}>
					<ReactQueryDevtools initialIsOpen={false} />
					<MobileAppBanner />
					<App />
				</QueryClientProvider>
			</SettingsProvider>
		</I18nextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
