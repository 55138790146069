//export const PHONE_REGEX = /^(?:(?:\+|00)33|0)\s*[67](?:[\s.-]*\d{2}){4}$/;

// simple french phone regexp without space or identifier
export const PHONE_REGEX = /^0[1-9][0-9]{8}$/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const DATE_REGEX =
	/^((0|)[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

export const CODE_REGEX = /^[0-9]{4}$/;

// Bank Infos
export const IBAN_REGEX =
	/^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/;

export const BIC_REGEX =
	/(^([a-zA-Z]){4})\s?(([a-zA-Z]){2})\s?(([0-9a-zA-Z]){2})\s?([0-9a-zA-Z]{3})?$/;

export const URL_REGEX = new RegExp(
	"^(https?:\\/\\/)?" + // protocol
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
		"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
		"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
		"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
		"(\\#[-a-z\\d_]*)?$",
	"i"
);

export const SSN_REGEX =
	/^([1278])\s?(\d{2})\s?(0[1-9]|1[0-2]|20)\s?(\d{2}|2[AB])\s?(\d{3})\s?(\d{3})\s?(\d{2})$/;

export const TEMPORARY_SSN_REGEX = /^\d{6}[\dab](\d)?$/;
