import React from "react";
import SVG from "../../atoms/SVG/SVG";
import DocPicto from "../../../assets/illustrations/cv.svg";
import { Document as ReactPDF, Thumbnail } from "react-pdf";
import { File } from "react-pdf/dist/esm/types/pdfjs";

interface IPDFThumbnail {
	file: File;
	fallbackPicto?: any;
}

const PDFThumbnail: React.FC<IPDFThumbnail> = ({ file, fallbackPicto }) => {
	const picto = fallbackPicto ?? DocPicto;
	// needed for it to work

	return (
		<ReactPDF
			file={file}
			loading={<SVG src={picto} />}
			noData={<SVG src={picto} />}
			error={<SVG src={picto} />}
			className="flex items-center justify-center h-full overflow-hidden rounded-8"
		>
			{Array.from(new Array(1), (el, index) => (
				<Thumbnail
					className="object-cover object-center h-full pointer-events-none [&>div>canvas]:!h-full [&>div>canvas]:!w-full [&>div]:rounded-8 [&>div]:overflow-hidden [&>div]:!min-w-[100px] [&>div]:h-full"
					key={`page_${index + 1}`}
					pageNumber={index + 1}
					scale={1}
				/>
			))}
		</ReactPDF>
	);
};

export default PDFThumbnail;
