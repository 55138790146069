import React from "react";
import { InfoMessage, Title, Button } from "@zolteam/react-ras-library";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import moment from "moment";
import IdentityInformations from "./IdentityInformations";
import AddressInformations from "./AddressInformations";
import SocialSecurityInformations from "./SocialSecurityInformations";
import ContactInformations from "./ContactInformations";
import FamilySituationInformations from "./FamilySituationInformations";
import AvatarEdit, {
	RemoveAvatarButton,
} from "../../components/molecules/AvatarEdit/AvatarEdit";
import { Form, Formik, FormikHelpers } from "formik";
import { PersonalInformationsFormValidationSchema } from "./PersonalInformationsFormValidation";
import {
	computePersonalInfosValuesForAPI,
	extractErrorsCodes,
} from "../../utils/personalInformations";
import { promiseToast } from "../../toastify/toastify";

export interface IPersonalInformationsValues {
	sex: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	bornCity: string;
	nativeCountry;
	nationality: string;
	ssn: string;
	addressStreet: string;
	addressBuilding: string;
	addressPostalCode: string;
	addressCity: string;
	addressCountry: {
		id?: string;
		name?: string;
	};
	phoneNumber: string;
	initialPhoneNumber: string;
	email: string;
	initialEmail: string;
	familySituation: string;
	childrenQty: number;
}

export interface IPersonalInformationsEditValues
	extends IPersonalInformationsValues {
	formattedDateOfBirth?: string;
	emailCheck?: boolean;
	phoneCheck?: boolean;
	familySituationOption?: {
		label: string;
		value: string;
	};
}

export const initialPersonalInformationsValues: IPersonalInformationsEditValues =
	{
		sex: "",
		firstName: "",
		lastName: "",
		dateOfBirth: "",
		bornCity: "",
		nativeCountry: "",
		nationality: "",

		ssn: "",

		addressStreet: "",
		addressBuilding: "",
		addressPostalCode: "",
		addressCity: "",
		addressCountry: {},

		phoneNumber: "",
		initialPhoneNumber: "",
		email: "",
		initialEmail: "",

		familySituation: "",
		childrenQty: 0,
	};

export interface IPersonalInformationsCategory {
	infos: any;
	disabled: boolean;
	refetchInfos: () => void;
}

const initialTouched = Object.keys(initialPersonalInformationsValues).reduce(
	(acc, key) => ({ ...acc, [key]: true }),
	{}
);

const IS_READ_ONLY = false;

const PersonalInformations: React.FC = () => {
	const queryClient = useQueryClient();
	const [Error, setError] = React.useState<any>();

	const fetch = () =>
		ProfileService.getProfile().then((resp) => {
			return {
				...resp,
				dateOfBirth: resp.dateOfBirth
					? new Date(resp.dateOfBirth)
					: null,
				formattedDateOfBirth: resp.dateOfBirth
					? moment(resp.dateOfBirth).format(t("dates.format"))
					: "",
				initialPhoneNumber: resp.phoneNumber,
				phoneNumber: resp.phoneNumber,
				initialEmail: resp.email,
				email: resp.email,
				childrenQty: resp.childrenQty || "0",
				mainLocation: "",
			};
		});

	const handleRefresh = () => {
		queryClient.resetQueries(["profile", "personalInformations"]);
	};

	const handleSubmit = (
		values: any,
		{ setFieldValue, setTouched, validateForm }: FormikHelpers<{}>
	) => {
		setError(null);
		const formValues = computePersonalInfosValuesForAPI(values);

		const prom = ProfileService.putProfile(formValues);

		prom.then(
			(resp) => {
				let hasCheck = false;
				if (
					values.phoneNumber &&
					values.initialPhoneNumber !== values.phoneNumber
				) {
					hasCheck = true;
					setFieldValue("phoneCheck", true);
				}
				if (values.email && values.initialEmail !== values.email) {
					hasCheck = true;
					setFieldValue("emailCheck", true);
				}
				if (!hasCheck) {
					handleRefresh();
				}

				return resp;
			},
			(err) => {
				setTouched(initialTouched).then(validateForm);
				setError(err);
				return;
			}
		);

		promiseToast(prom);
		return prom;
	};

	const { isLoading, data } = useQuery(
		["profile", "personalInformations"],
		fetch,
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const initialValues = {
		...initialPersonalInformationsValues,
		...data,
	};

	return (
		<div className={cn([IS_READ_ONLY && "cursor-not-allowed"])}>
			<Title tag="h1" className="!leading-7 mb-6">
				{t("personalInfos.title")}
			</Title>
			{IS_READ_ONLY && (
				<div className="mb-4">
					<InfoMessage color="warning" withIcon>
						{t("personalInfos.readOnly")}
					</InfoMessage>
				</div>
			)}

			{isLoading ? (
				<Loader />
			) : (
				<div
					className={cn([
						"relative flex flex-col gap-6 w-full",
						IS_READ_ONLY && "pointer-events-none opacity-70",
					])}
				>
					<div className="flex flex-col items-center justify-center w-full gap-4 p-4 sm:flex-row sm:justify-start">
						<AvatarEdit />
						<RemoveAvatarButton />
					</div>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={PersonalInformationsFormValidationSchema()}
						validateOnChange
						validateOnBlur
						initialTouched={Object.keys(
							initialPersonalInformationsValues
						).reduce((acc, key) => ({ ...acc, [key]: true }), {})} // Force validation on first render
					>
						{(helpers) => {
							const { values, dirty, isSubmitting, touched } =
								helpers;

							const isDisabled = IS_READ_ONLY || isSubmitting;

							return (
								<Form className="relative flex flex-col items-start justify-start gap-4 max-w-[900px]">
									<IdentityInformations
										infos={values}
										disabled={isDisabled}
										refetchInfos={handleRefresh}
									/>
									<hr />
									<SocialSecurityInformations
										infos={values}
										disabled={isDisabled}
										refetchInfos={handleRefresh}
									/>
									<hr />
									<ContactInformations
										infos={values}
										disabled={isDisabled}
										refetchInfos={handleRefresh}
									/>
									<hr />
									<AddressInformations
										infos={values}
										disabled={isDisabled}
										refetchInfos={handleRefresh}
									/>
									<hr />
									<FamilySituationInformations
										infos={values}
										disabled={isDisabled}
										refetchInfos={handleRefresh}
									/>
									{!!Object.keys(touched).length && (
										<div className="sticky bottom-0 flex items-center justify-end w-full gap-4 pb-2 bg-white">
											{!!Error && (
												<InfoMessage
													color="error"
													withIcon
												>
													{Error?.response?.data
														?.errors?.length ? (
														<ul>
															{extractErrorsCodes(
																Error?.response
																	?.data
																	?.errors
															)?.map((a) => (
																<li key={a}>
																	{t(
																		`API_ERRORS.${a}`
																	)}
																</li>
															))}
														</ul>
													) : (
														Error?.response?.data
															?.message
													)}
												</InfoMessage>
											)}
											<Button
												color="primary"
												type="submit"
												disabled={isLoading || !dirty}
												isLoading={isSubmitting}
												className="w-full mt-2 lg:w-auto"
											>
												{t("global.save")}
											</Button>
										</div>
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
			)}
		</div>
	);
};

export default PersonalInformations;
