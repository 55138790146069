import React, { useContext, useEffect, useRef } from "react";
import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { useInfiniteQuery } from "@tanstack/react-query";
import DocImage from "./assets/document.svg";
import { DefaultTFuncReturn, t } from "i18next";
import ListItem, { IListItem } from "../../atoms/ListItem/ListItem";
import { FiltersContext } from "../../../contexts/FiltersContext";
import Loader from "../../atoms/Loader/Loader";
import SVG from "../../atoms/SVG/SVG";
import cn from "../../../utils/cn";
import { IPagination } from "../../templates/ListLayout/ListLayout";
import Spinner from "../../atoms/Spinner/Spinner";

interface IList {
	className?: string;
	onItemClick?: (data) => void;
	items?: IListItem[] | any[];
	total?: number;
	backgroundImage?: any;
	noDataMessage?: string | DefaultTFuncReturn;
	isLoading?: boolean;
	isFetching?: boolean;
	fetchNextPage?: () => void;
	hasMore?: boolean;
	children?: React.FC<any>;
	itemIdKey?: string;
	activeItemId?: string | number | null;
	showResultsCount?: boolean;

	pagination: IPagination | false;
	setPagination: (pagination: IPagination) => void;
	scrollParentId?: any;
}

interface IAsyncList extends IList {
	queryKey: string[] | undefined;
	queryFn?: (filters) => Promise<any>;
	queryOpts?: any;
}

const List: React.FC<IList> = ({
	onItemClick,
	items,
	backgroundImage,
	noDataMessage,
	isLoading = false,
	children,
	showResultsCount,
	className,
	activeItemId = null,
	scrollParentId,
	...props
}) => {
	const isInitied = useRef(false);

	useEffect(() => {
		if (!activeItemId || isInitied.current) return;

		isInitied.current = true;
		const child = items?.find((child) => {
			const childId = child[props.itemIdKey ?? "id"].toString();
			const activeId = activeItemId.toString();

			return childId === activeId;
		}) ?? {
			[props.itemIdKey ?? "id"]: -1,
			found: false,
		};

		if (onItemClick) onItemClick(child);
	});

	if (isLoading)
		return (
			<div className="flex flex-col items-center w-full h-full overflow-auto gap-xs">
				<Loader />
			</div>
		);

	if (!items?.length)
		return (
			<div className="flex flex-col items-center mt-xl max-w-[500px] self-center w-full">
				<InfoMessage withIcon>
					{noDataMessage ?? t("docs.noDocuments")}
				</InfoMessage>
				<SVG src={backgroundImage ?? DocImage} className="mt-8" />
			</div>
		);

	return (
		<>
			{showResultsCount && (
				<div className="flex flex-col my-m">
					{props.total} {t("offers.searchOffers.numberOffers")}
				</div>
			)}
			<div
				className={cn([
					"flex flex-col overflow-auto gap-xs [&>div:last-child]:border-none",
					className,
				])}
			>
				{items.map((item: IListItem, index) => {
					const itemId = props.itemIdKey
						? item[props.itemIdKey]
						: item.id;
					return (
						<div
							key={itemId}
							className={"border-neutral-100 border-b-2 pb-1"}
						>
							<ListItem
								key={itemId}
								id={itemId}
								item={item}
								isActive={activeItemId === itemId}
								onClick={() => onItemClick && onItemClick(item)}
							>
								{() => {
									return children && children(item, index);
								}}
							</ListItem>
						</div>
					);
				})}
			</div>
			<span className="w-full text-center mt-m">
				{props.isFetching && <Spinner />}
				{props.pagination !== false && !props.isFetching ? (
					props.hasMore ? (
						<Button
							type="button"
							className="!p-1 !px-2 !text-neutral-600"
							color="grey"
							onClick={() => {
								props.fetchNextPage && props.fetchNextPage();
							}}
						>
							Charger plus d'éléments
						</Button>
					) : (
						<span className="text-neutral-500">
							Fin de la liste
						</span>
					)
				) : (
					false
				)}
			</span>
		</>
	);
};

export const AsyncList: React.FC<IAsyncList> = ({
	pagination,
	setPagination,
	scrollParentId = "App",
	...props
}) => {
	const ScrollParent = scrollParentId
		? document.getElementById(scrollParentId)
		: document.body;

	const HasMore = useRef(true);

	const { hideFilterButton, showFilterButton, getFilters } =
		useContext(FiltersContext);

	const handleScroll = (e) => {
		if (isFetching) return;
		const { scrollTop, scrollHeight, clientHeight } =
			ScrollParent ?? document.body;

		if (
			scrollTop + clientHeight >= scrollHeight * 0.85 &&
			HasMore.current
		) {
			fetchNextPage();
		}
	};

	useEffect(() => {
		if (!ScrollParent) return;
		ScrollParent.addEventListener("scroll", handleScroll);
		return () => {
			if (!ScrollParent) return;
			ScrollParent.removeEventListener("scroll", handleScroll);
		};
	});

	const filters = getFilters();

	const { data, isLoading, fetchNextPage, isFetching, error } =
		useInfiniteQuery(
			props.queryKey ?? [],
			({ pageParam = 1, ...queryProps }) =>
				props.queryFn &&
				props
					.queryFn({
						...getFilters(),
						// ...pagination,
						page: pageParam,
						...queryProps,
					})
					.then((res) => ({
						...res,
						nextPage: pageParam + 1,
					})),
			{
				onSuccess: (resp) => {
					const items = resp.pages.reduce(
						(acc, page) => [...acc, ...page.items],
						[]
					);
					HasMore.current =
						!resp?.pages.find((page) => page.isLastPage) && // isLastpage is returned only for offer
						items.length < resp.pages[0].total; // so we need to check if we have all the items for the other pages
					showFilterButton();
					return resp;
				},
				onError: () => {
					hideFilterButton();
				},
				getNextPageParam: (lastPage) => {
					return lastPage.nextPage;
				},
				refetchOnWindowFocus: false,
				enabled:
					HasMore.current ||
					!filters.searchInput?.length ||
					!!filters.searchInput?.length,
				...props.queryOpts,
			}
		);

	if (error)
		return (
			<InfoMessage color="error" withIcon>
				{t("docs.loadingError")}
			</InfoMessage>
		);

	if (isLoading) return <Loader />;

	const items = data?.pages.reduce(
		(acc, page) => [...acc, ...page.items],
		[]
	);

	const total = data?.pages[0].total ?? 0;

	return (
		<>
			<List
				{...props}
				items={items}
				total={total}
				isLoading={isLoading}
				fetchNextPage={fetchNextPage}
				isFetching={isFetching}
				hasMore={HasMore.current}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</>
	);
};

export default List;
