import React from "react";
import Payslip from "../../components/molecules/Payslip/Payslip";
import { t } from "i18next";
import moment from "moment";
import { useParams } from "react-router-dom";
import ListLayout from "../../components/templates/ListLayout/ListLayout";
import PDFViewer from "../../components/molecules/PDFViewer/PDFViewer";
import PayslipService from "../../services/PayslipService";
import PayslipsIcon from "../../assets/illustrations/payslips.svg";
import DocsFiltersForm, {
	DEFAULT_DOCS_FILTERS,
	IDocsFilters,
	countDocsFilters,
} from "../../forms/FiltersForm/DocsFiltersForm";
import { PROXY_URL } from "../../constants/constants";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const Payslips: React.FC = () => {
	const params = useParams();

	const getFormatedFiltersForAPI = (filters: IDocsFilters) => {
		let newFilters = {
			...filters,
			months:
				filters.months?.map((a) =>
					typeof a === "object" ? a.value : a
				) ?? [],
			years:
				filters.years?.map((a) =>
					typeof a === "object" ? a.value : a
				) ?? [],
			agencies:
				filters.agencies?.map((a) =>
					(typeof a === "object" ? a.label : a).toString()
				) ?? [],
		};
		return newFilters;
	};

	const fetchPayslips = (filters: IDocsFilters) => {
		let prom = PayslipService.getPayslips(
			getFormatedFiltersForAPI(filters)
		);
		return prom;
	};

	const formatDownloadTitle = (doc) => {
		return `${t("payslips.fileName")}_${moment(doc.date * 1000).format(
			"MMMM_YYYY"
		)}_${doc.agencyName}_${doc.id}`;
	};

	return (
		<ListLayout
			title={t("payslips.title").toString()}
			query={{
				key: ["payslips"],
				fn: fetchPayslips,
			}}
			backgroundImage={PayslipsIcon}
			noDataMessage={t("payslips.noPayslips")}
			filters={{
				values: DEFAULT_DOCS_FILTERS,
				count: countDocsFilters,
				modalForm: DocsFiltersForm,
				gtmEvent: "paie__voir_filtres",
				height: "3xl:h-[68vh]",
			}}
			baseUrl="/payslips"
			activeItemId={params.id ?? null}
			onItemClick={(item) => {
				pushDataLayer({
					dataLayer: {
						event: "paie__voir_bulletin",
						parcours: "Paies",
					},
				});
			}}
			viewer={{
				render: ({ item, closeViewer }) => {
					return (
						<PDFViewer
							onBack={closeViewer}
							download={true}
							downloadTitle={
								item ? formatDownloadTitle(item) : undefined
							}
							onDownloaded={() => {
								pushDataLayer({
									dataLayer: {
										event: "paie__telecharger_bulletin",
									},
								});
							}}
							file={PROXY_URL + item?.path ?? false}
						/>
					);
				},
			}}
		>
			{(item) => <Payslip {...item} />}
		</ListLayout>
	);
};

export default Payslips;
